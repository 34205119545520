import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthProvider } from './context/AuthProvider'
import './scss/style.scss'
import ContentLoading from './components/ContentLoading'
import RedirectIfLoggedIn from "./components/RedirectIfLoggedIn";
import RequireAuth from "./components/RequireAuth";

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <AuthProvider>
          <Suspense fallback={<ContentLoading />}>
            <Routes>
              <Route element={<RedirectIfLoggedIn />}>
                <Route exact path="/login" name="Login Page" element={<Login />} />
              </Route>
              <Route element={<RequireAuth />}>
                <Route path="*" name="Home" element={<DefaultLayout />} />
              </Route>
            </Routes>
          </Suspense>
        </AuthProvider>
      </BrowserRouter>
    )
  }
}

export default App
