import React  from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import useAuth from '../hooks/useAuth'

const RedirectIfLoggedIn = () => {
  const { auth } = useAuth()

  if( auth?.user ) {
    const redirectTo = sessionStorage.getItem('prevPath') || '/'
    sessionStorage.removeItem('prevPath')

    return <Navigate to={redirectTo} replace />
  }

  return <Outlet />
}

export default RedirectIfLoggedIn
