import React, { createContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({})
  const location = useLocation()

  useEffect(() => {
    const loggedInUser = localStorage.getItem('user')

    if( loggedInUser ) {
      const foundUser = JSON.parse(loggedInUser)
      setAuth(foundUser)
    }

    sessionStorage.setItem('prevPath', location.pathname)
  }, [])

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node
}

export default AuthContext
