import React from 'react'
import { CCol, CRow, CSpinner } from '@coreui/react-pro'


const contentLoading = () => {
  return (
    <CRow>
      <CCol>
        <CSpinner color="secondary" className="content-loading-spinner" />
      </CCol>
    </CRow>
  )
}

export default contentLoading
